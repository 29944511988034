<template>
  <div
    ref="containerElement"
    class="teaser-featured-projects"
    data-horizontal-module
  >
    <div
      v-for="(p, index) in data.featuredProjects"
      ref="projectElement"
      :key="p.id"
      class="project"
      @click="
        (event) =>
          goToProject(
            event,
            {
              name: `projects-slug___${locale}`,
              params: { slug: p.translatedSlug },
            },
            index,
          )
      "
    >
      <div class="link" style="pointer-events: none">
        <div class="figurewrapper">
          <figure
            v-if="p[imageSizes[index % 3]]"
            class="imagewithcaption _hidden-vs"
            :class="{
              '-active': active === p.id,
            }"
          >
            <NakedImage
              v-if="p.image_3_2?.image?.responsiveImage"
              class="image"
              picture-class="picture"
              image-class="image"
              :data="p.image_3_2.image.responsiveImage"
            />
            <figcaption class="caption">
              <p class="title text-md">
                {{ p.title }}
              </p>
              <p class="location text-sm">{{ p.location }}</p>
            </figcaption>
          </figure>
          <figure
            v-if="p.image_2_3"
            class="imagewithcaption _hidden-vl"
            :class="{
              '-active': active === p.id,
            }"
          >
            <NakedImage
              v-if="p.image_2_3.image && p.image_2_3.image.responsiveImage"
              class="image"
              :data="p.image_2_3.image.responsiveImage"
            />
            <p class="_hidden-vl location text-sm">{{ p.location }}</p>
            <figcaption class="caption text-lg">
              {{ p.longTitle }}
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
    <div class="cta">
      <NuxtLink class="link button-primary text-md" :to="linkProjects">
        {{ t('allProjects') }}
        <span class="arrow"></span>
      </NuxtLink>
    </div>
  </div>
</template>

<i18n lang="yaml">
de:
  allProjects: Alle Referenzen
fr:
  allProjects: Tous les projets
</i18n>

<script setup lang="ts">
import gsap from 'gsap';
import { NakedImage } from 'vue-datocms';
import type { RouteLocation } from 'vue-router';
import type { TeaserFeaturedProjectsFragment } from '#gql';
import { removeTimelineAnimation } from '~/utils/removeTimelineAnimation';

const { isVl } = useViewportSize();
const { locale, t } = useI18n();
const active = useState();

const router = useRouter();

const props = defineProps<{
  data: TeaserFeaturedProjectsFragment;
  horizontalScrollTimeline: gsap.core.Timeline;
}>();

const containerElement = ref<HTMLElement | null>(null);
const projectElement = ref<HTMLElement[]>([]);

const imageSizes: ('image_3_2' | 'image_2_3')[] = [
  'image_2_3',
  'image_3_2',
  'image_3_2',
];

let tl: gsap.core.Timeline | null;

const tweens = ref<HTMLElement[]>([]);

const linkProjects = computed(function () {
  if (locale.value === 'fr') {
    return '/fr/projets';
  }

  return '/de/referenzen';
});

watch(isVl, function () {
  if (!isVl.value) {
    removeTimelineAnimation(tl, tweens);
  }
});

onMounted(async function () {
  await nextTick();

  if (isVl.value && containerElement.value) {
    addHorizontalAnimation(
      containerElement.value,
      props.horizontalScrollTimeline,
    );
  }

  const projects = Array.from(
    document.querySelectorAll('.teaser-featured-projects .project'),
  );

  for (let i = 0; i < projects.length; i++) {
    const p = projects[i];
    const box = p.getBoundingClientRect();
    const scrollToCenter = box.left;

    p.setAttribute('data-scroll-to-center', `${Math.round(scrollToCenter)}`);
  }
});

function goToProject(event: PointerEvent, route: RouteLocation, index: number) {
  for (let i = 0; i < projectElement.value.length; i++) {
    const p = projectElement.value[i];

    if (i < index) {
      p.setAttribute('data-sibling', 'left');
    } else if (i > index) {
      p.setAttribute('data-sibling', 'right');
    }
  }

  if (isVl.value && event.target instanceof HTMLElement) {
    event.target.setAttribute('data-transition-element', `true`);

    const figure = event.target.querySelector('figure');
    const picture = figure?.querySelector('picture');

    if (!figure || !picture) {
      return;
    }

    const box = picture.getBoundingClientRect();

    const clipPath = figure.style.clipPath as string;
    const clipping = clipPath
      .replaceAll('inset(', '')
      .replaceAll(')', '')
      .split(' ')
      .map((it) => Number(it.replaceAll('%', '')));

    for (let i = 0; i < 4; i++) {
      if (!clipping[i]) {
        clipping[i] = 0;
      }
    }

    const boxWithClipping = {
      top: box.top + clipping[0] * 0.01 * box.height,
      right: box.left - clipping[1] * 0.01 * box.width,
      bottom: box.bottom - clipping[2] * 0.01 * box.height,
      left: box.left + clipping[3] * 0.01 * box.width,
      height:
        box.height -
        clipping[0] * 0.01 * box.height -
        clipping[2] * 0.01 * box.height,
      width:
        box.width -
        clipping[1] * 0.01 * box.width -
        clipping[3] * 0.01 * box.width,
    };

    window.PageTransitionData = {
      featuredProjectRect: boxWithClipping,
    };
  }

  router.push(route);
}

function addHorizontalAnimation(
  containerEl: HTMLElement,
  masterTl: gsap.core.Timeline,
) {
  tl = gsap.timeline({
    ease: 'none',
    scrollTrigger: {
      trigger: containerEl,
      containerAnimation: masterTl,
      scrub: 0.5,
      start: window.innerWidth * 0.3,
      // start: -window.innerWidth * 0.25,
      end: '30%',
      horizontal: true,
    },
  });

  tweens.value.push(...projectElement.value);

  tl.fromTo(
    projectElement.value[1],
    {
      xPercent: -50,
    },
    {
      xPercent: 0,
      duration: 0.2,
      delay: 0.05,
      ease: 'linear',
    },
  )
    .fromTo(
      projectElement.value[2],
      {
        xPercent: -125,
      },
      {
        xPercent: -50,
        duration: 0.2,
        ease: 'linear',
      },
      '<',
    )
    .to(projectElement.value[2], {
      xPercent: 0,
      duration: 0.2,
      ease: 'linear',
    });

  const clipPathOrigin = [
    'inset(20% 35% 40% 10%)',
    'inset(40% 40% 0% 25%)',
    'inset(10% 35% 55% 25%)',
  ];

  for (let i = 0; i < projectElement.value.length; i++) {
    const project = projectElement.value[i];
    const figure = project.querySelector('figure') as HTMLElement;

    tweens.value.push(figure);

    tl.fromTo(
      figure,
      { clipPath: clipPathOrigin[i] },
      {
        clipPath: 'inset(0% 0% 0% 0%)',
        ease: 'power2.in',
        duration: 0.2,
        delay: i * 0.1,
      },
      i === 0 ? '<-=100%' : '<',
    );
  }
}
</script>

<style scoped lang="scss">
.teaser-featured-projects {
  @media (--vs) {
    padding: 0 var(--base-component-padding-inline) 2.5rem
      var(--base-component-padding-inline);

    > .project {
      &:not(:last-of-type) {
        padding-bottom: 2.5rem;
      }

      > .link {
        > .figurewrapper {
          @media (max-aspect-ratio: 1/1) {
            aspect-ratio: 2 / 3;
          }

          @media (min-aspect-ratio: 1/1) {
            width: 100%;
            position: relative;
            overflow: hidden;

            > .imagewithcaption {
              :deep(picture) {
                position: relative;
                max-height: 80lvh;

                > img {
                  max-height: 80lvh;
                }
              }
            }
          }

          > .imagewithcaption {
            :deep(img) {
              width: 100% !important;
              height: 100% !important;
              object-fit: cover;
              max-width: unset !important;
            }

            > .location {
              color: var(--color-stone-500);
              padding: 0.5rem 0;
            }
          }
        }
      }
    }

    > .cta {
      padding: 4rem 0 0 0;
      text-align: right;

      > .link {
        --size-arrow: 1.25rem;
      }
    }
  }

  @media (--vl) {
    height: 100lvh;
    display: flex;
    padding: var(--margin-top) 0 4.25rem 0;
    gap: 5rem;

    > .project {
      --inner-height: calc(100lvh - var(--margin-top));

      cursor: pointer;
      position: relative;
      height: var(--inner-height);
      width: var(--image-width);

      &:nth-of-type(1) {
        --image-height: calc(100lvh - var(--margin-top) - 4.25rem - 2rem);
        --image-width: calc(var(--image-height) / 3 * 2);

        :deep(picture) {
          img {
            object-position: 85% 50% !important;
          }
        }
      }

      &:nth-of-type(2) {
        margin-top: 4rem;
        --image-height: calc(100lvh - var(--margin-top) - 4.25rem - 12rem);
        --image-width: calc(var(--image-height) / 2 * 3);
      }

      &:nth-of-type(3) {
        --image-height: calc(100lvh - var(--margin-top) - 4.25rem - 2.25rem);
        --image-width: calc(var(--image-height) / 2 * 3);
      }

      > .link {
        color: var(--color-black);
        display: block;
        height: 100%;
        width: 100%;
        outline: none;
        text-decoration: none;
        border: none;

        > .figurewrapper {
          position: relative;
          width: 100%;

          > .imagewithcaption {
            height: var(--inner-height);
            display: grid;
            grid-template-rows: minmax(0, auto) minmax(0, 1fr);
            gap: 1rem;
            width: 100%;

            :deep(picture) {
              width: var(--image-width);
              height: var(--image-height);
              overflow: visible;

              > img {
                transform-origin: bottom center;
                max-width: unset !important;
                object-fit: cover;
                height: 100% !important;
                width: 100% !important;
                object-position: center;
              }
            }

            > .caption {
              flex-shrink: 0;
              display: flex;
              gap: 1rem;
              justify-content: space-between;
              max-width: var(--image-width);

              > .title {
                max-width: 75%;
              }

              > .location {
                color: var(--color-stone-500);
                flex-shrink: 0;
                width: fit-content;
                transform: translateY(0.3rem);
              }
            }
          }
        }
      }
    }

    > .cta {
      display: flex;
      align-items: flex-end;
      padding-right: 9.5rem;
      margin-bottom: -0.6rem;
      margin-left: 1rem;

      > .link {
        display: block;
        width: max-content;
        border-bottom: 1.5px solid currentColor;
        padding: 0;
        outline-offset: 0.25rem;
        position: relative;

        > .arrow {
          display: none;
        }
      }
    }
  }

  > .project {
    > .link {
      > .figurewrapper {
        > .imagewithcaption {
          &.-active {
            contain: layout;
          }
        }
      }
    }
  }
}
</style>
