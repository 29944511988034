<template>
  <figure class="image-with-caption">
    <NakedImage
      v-if="image && image.responsiveImage"
      class="image"
      :data="{ ...image.responsiveImage, alt: '' }"
    />
    <figcaption v-if="data.caption" class="caption text-sm">
      {{ data.caption }}
    </figcaption>
  </figure>
</template>

<script setup lang="ts">
import { NakedImage } from 'vue-datocms';
import type { ImageWithCaptionFragment } from '#gql';

type ImageFormat = 'original' | 'landscape' | 'portrait';

const props = defineProps<{
  data: ImageWithCaptionFragment;
  format?: ImageFormat;
}>();

const image = computed(function () {
  if (props.format === 'landscape') {
    return props.data.imageLandscape;
  } else if (props.format === 'portrait') {
    return props.data.imagePortrait;
  }

  return props.data.image;
});
</script>

<style scoped lang="scss">
.image-with-caption {
  position: relative;
  display: grid;
  grid-template-rows: minmax(0, 1fr) minmax(0, auto);
  height: 100%;
  width: 100%;
  text-align: right;

  :deep(picture) {
    width: 100%;
    height: 100%;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
    }
  }

  > .caption {
    color: currentColor;
    padding-top: 0.5rem;
  }
}
</style>
